<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// import DeviceTemp from "./devices-events";

import {
  layoutComputed,
  authComputed,
  devicesComputed,
  devicesMethods,
} from "@/state/helpers";
/**
 * multiple devices component
 */
export default {
  page: {
    title: "Multiple Device",
    meta: [{ name: "Multiple Device" }],
  },
  components: {
    Layout,
    PageHeader,
    // DeviceTemp,
  },
  data() {
    return {
      title: "titles.device.text",
      eventsData: [],
      eventsLoader: false,
    };
  },
  created() {
    let serials = ["865067024811344", "869170033631582"]//this.$route.params.serials;
    this.retrieveEvents({ serials: serials });
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...devicesComputed,

    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.device.text"),
          active: true,
        },
      ];
    },
  },
  methods: {
    ...devicesMethods,

    retrieveEvents({ serials }) {
      let params = {
        serial: {$in: serials},
        sortBy: "createdAt:desc",
        limit: 1000
      };
      this.eventsLoader = true;
      return this.getEventsBy(params)
        .then((events) => {
          this.eventsLoader = false;
          return events;
        })
        .catch((error) => {
          return error;
        });
    },
    
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    
  },
  watch: {
    getEvents(newVal) {
      this.eventsData = newVal.results;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
  </Layout>
</template>